var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "member", on: { click: _vm.clickMember } }, [
    _c("div", { staticClass: "profile-wrapper" }, [
      _c("div", { staticClass: "img-wrapper" }),
      _vm._v(" "),
      _c("img", {
        staticClass: "profile-img",
        attrs: { src: _vm.member.profile_image_url }
      })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "name-wrapper" }, [
      _c("p", { staticClass: "name" }, [
        _vm._v("\n      " + _vm._s(_vm.getName) + "\n    ")
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "position" }, [
        _vm._v("\n      " + _vm._s(_vm.getPosition) + "\n    ")
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }