<template>
  <div>
    <ul class="news-list">
      <li class="news" v-for="n in news" :key="n.id">
        <a :href="n.link_url" target="_blank">
          <div class="news-header">
            <span class="media-name">{{n.media_name}}</span>
            <span class="published_at">{{n.published_at}}</span>
          </div>
          <div class="news-title-wrapper">
            <p class="title ellipsis cut-2">
              {{n.title}}
            </p>
          </div>
        </a>
      </li>
    </ul>

    <div class="more-wrapper" v-if="hasMore">
      <div class="more-btn" @click="loadMore">
        Load more
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    news: {
      type: Array,
      default: [],
    },
    hasMore: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
    }
  },
  methods: {
    loadMore() {
      this.$emit('loadMore');
    }
  }
}
</script>