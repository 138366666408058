var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "team" }, [
    _c("section", { staticClass: "team-hero section-container" }, [
      _c("div", { staticClass: "content-container" }, [
        _c("div", { staticClass: "text-wrapper" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("p", { staticClass: "type-d3 section-description1" }, [
            _vm._v(
              "\n          " +
                _vm._s(_vm.$t("message.team.section1")) +
                "\n        "
            )
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "type-d3 section-description2" }, [
            _vm._v(
              "\n          " +
                _vm._s(_vm.$t("message.team.section1d")) +
                "\n        "
            )
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "team-list-container section-container" },
      [_c("member-list", { key: _vm.lang, attrs: { members: _vm.members } })],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h1", { staticClass: "type-t3 section-title" }, [
      _c("span", { staticClass: "break" }, [_vm._v("Team")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }