var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "landing" },
    [
      _c("section", { staticClass: "landing-hero section-container" }, [
        _c("div", { staticClass: "content-container" }, [
          _c("div", { staticClass: "text-wrapper" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("p", { staticClass: "type-d1 section-description" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("message.home.section1")) +
                  "\n        "
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "button-wrapper" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "button button-bordered type-b1",
                    attrs: { to: { name: "aboutIndex" } }
                  },
                  [
                    _c("span", [
                      _vm._v(" " + _vm._s(_vm.$t("message.home.learn")) + " ")
                    ])
                  ]
                )
              ],
              1
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("section", { staticClass: "landing-portfolio section-container" }, [
        _c(
          "div",
          { staticClass: "content-container" },
          [
            _c("p", { staticClass: "type-t2" }, [
              _vm._v("\n        Our portfolio\n      ")
            ]),
            _vm._v(" "),
            _c("landing-portfolio", {
              attrs: { portfolios: _vm.portfolios, "has-service-link": false },
              on: { portfolioClicked: _vm.portfolioClicked }
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "button-wrapper" },
              [
                _c(
                  "router-link",
                  {
                    staticClass:
                      "button button-bordered button-bordered-main type-b1",
                    attrs: { to: { name: "portfolioIndex" } }
                  },
                  [_c("span", [_vm._v(_vm._s(_vm.$t("message.home.vp")))])]
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("section", { staticClass: "landing-team section-container" }, [
        _c("div", { staticClass: "content-container" }, [
          _c("p", { staticClass: "type-t2" }, [
            _vm._v("\n        Team\n      ")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "description-wrapper type-d2" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("message.home.section2")) +
                "\n      "
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "button-wrapper" },
            [
              _c(
                "router-link",
                {
                  staticClass: "button button-bordered type-b1",
                  attrs: { to: { name: "teamIndex" } }
                },
                [_c("span", [_vm._v(_vm._s(_vm.$t("message.home.vm")))])]
              )
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c("section", { staticClass: "landing-news section-container" }, [
        _c(
          "div",
          { staticClass: "content-container" },
          [
            _c("p", { staticClass: "type-t2" }, [
              _vm._v("\n        Featured News & Events\n      ")
            ]),
            _vm._v(" "),
            _c("landing-news", {
              attrs: { news: _vm.news, "has-service-link": true }
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "button-wrapper" },
              [
                _c(
                  "router-link",
                  {
                    staticClass:
                      "button button-bordered button-bordered-main type-b1",
                    attrs: { to: { name: "newsIndex" } }
                  },
                  [_c("span", [_vm._v(_vm._s(_vm.$t("message.home.vn")))])]
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _vm.showDetail && _vm.portfolio != null
        ? _c("portfolio-detail", {
            attrs: { portfolio: _vm.portfolio },
            on: { closeModal: _vm.closeModal }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "h1",
      { staticClass: "type-t1 section-title", staticStyle: { color: "#fff" } },
      [
        _c("span", { staticClass: "break" }, [_vm._v("Venture investment")]),
        _vm._v("\n          for going global\n        ")
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }