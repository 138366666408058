<template>
  <div class="members-wrapper">
    <member-item :member="member" :hasServiceLink=true v-for="member in members" :key="member.id" />
  </div>
</template>

<script>
import MemberItem from "./member";
export default {
  props: {
    members: {
      type: Array,
      default: [],
    }
  },
  data() {
    return {
    }
  },
  components: {
    MemberItem
  }
}
</script>