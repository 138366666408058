<template>
  <div class='container'>
    <nav-top />

    <div class='row'>
      <slot/>
    </div>

    <footer-bottom />
  </div>
</template>

<script>
import FooterBottom from './footer';
import NavTop from './navTop';

export default {
  components: {
    FooterBottom, NavTop
  }
}
</script>
