import Vue from 'vue';
import Vuelidate from "vuelidate";
import router from '../service/routes';
import App from '../service/app'
import VueCookies from 'vue-cookies';
import common from '../lib/common';
import Antd from 'ant-design-vue';
import VueSimpleAlert from "vue-simple-alert";
import VueI18n from 'vue-i18n';
import 'ant-design-vue/dist/antd.css';

Vue.config.productionTip = false;

window._ = require('lodash');
window.moment = require('moment');
window.common = common;
window.axios = require('axios');
axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.withCredentials = true

// 요청 인터셉터
// axios.defaults.baseURL = window.apiHost;
axios.interceptors.request.use(function (config) {
  const csrfToken = document.querySelector("meta[name=csrf-token]").content;
  config.headers.common["X-CSRF-Token"] = csrfToken;
  return config;
}, function (error) {
  return Promise.reject(error);
});

axios.interceptors.response.use( function (response) {
  return response;
}, function (error) {
  return Promise.reject(error);
});

Vue.use(Vuelidate);
Vue.use(VueCookies);
Vue.use(Antd);
Vue.use(VueSimpleAlert);
Vue.use(VueI18n);

const messages = {
  en: {
    message: {
      home: {
        section1: 'Translink investment was established as a strong VC in Silicon Valley in the US to invigorate the South Korean startup ecosystem and their successful overseas advancement.',
        learn: 'Learn more about us',
        vp: 'View our portfolio',
        section2: 'The Translink Team has global business experiences and extensive networks in Silicon Valley.',
        vm: 'View all members',
        vn: 'View all news & events',
      },
      about: {
        section1: 'A VC firm specializing in supporting South Korean venture enterprises’ global expansion.',
        section1d: 'Translink Investment was established in 2015 to flourish the South Korean startup market and to aid companies’ successful global expansion. Our partners have long careers in the CVC and VC industry, various experiences in startup enterprises, and growth capital investment experience.',
        section2_title: 'Our Key Industries & Strengths',
        section2: 'Based on our unique ‘Collaborative Investment Model’, Translink Investment provides opportunities for various rising startups with global business potential to successfully expand.',
        section2d: 'Translink Investment is committed to being a reputable partner for South Korean startups seeking to claim the global market, providing stable asset management services as well as Translink Capital’s solid partnership networks encompassing Asia and the US.',
      },
      team: {
        section1: 'The Translink Team has global business experiences and extensive networks in Silicon Valley.',
        section1d: 'Meet our worldwide team.',
      },
      portfolio: {
        section1: 'Translink helps its portfolio companies to grow in multiple dimensions.',
      },
      categories: {
        all: 'ALL',
        tn: 'Translink News',
        pn: 'Portfolio News',
        event: 'Events',
      },
      sort: {
        newest: 'Newest First',
        popular: 'Most Popular',
      },
      news: {},
      content: {
        playtime: 'Play time'
      },
      footer: {
        address: '5F, 551 Seolleung-ro, Gangnam-gu, Seoul Korea, 06145',
      },
    }
  },
  ko: {
    message: {
      home: {
        section1: 'Translink Investment는 국내 벤처시장의 활성화와 스타트업의 성공적인 해외 진출을 목표로 한국 파트너들과 미국 실리콘밸리 소재 중견 VC인 Translink Capital이 합작 설립한 창업 투자 회사입니다.',
        learn: '더 알아보기',
        vp: '모든 포트폴리오 보기',
        section2: '글로벌 인사이트와 실리콘밸리 네트워크를 보유한 트랜스링크 팀',
        vm: '모든 멤버 보기',
        vn: '모든 뉴스 & 이벤트 보기',
      },
      about: {
        section1: '국내 중소/벤처기업의 글로벌 진출 지원 전문 창업투자회사',
        section1d: 'Translink Investment는 국내 스타트업 생태계의 활성화와 국내외 스타트업의 성공적인 글로벌 진출을 목표로 하는 창업투자회사(Venture Capital)입니다.  2015년 실리콘밸리 소재 VC인 Translink Capital이 공동설립 했으며, CVC와 VC 업계에서의 오랜 경력, 스타트업 창업경험, Growth Capital 투자경험 등을 보유한 파트너들로 구성되어 있습니다.',
        section2_title: '주요 산업 및 경쟁력',
        section2: 'Translink Investment는  ‘Collaborative 투자 모델’을 바탕으로 글로벌 비즈니스 가능성이 있는 다양한 성장단계의 기술 기반 스타트업을 발굴하여 성공적으로 해외에 진출할 수 있도록 지원합니다.',
        section2d: 'Translink Investment의 기업 발굴 및 사업개발 능력에 Translink Capital의 풍부한 글로벌 네트워크를 더해, 세계를 향해 비상하고자 하는 국내외 스타트업의 든든한 동반자가 되겠습니다.',
      },
      team: {
        section1: '글로벌 인사이트와 실리콘밸리 네트워크를 보유한 트랜스링크 팀',
        section1d: '',
      },
      portfolio: {
        section1: '포트폴리오의 성장을 통해 함께 성장하는 트랜스링크',
      },
      categories: {
        all: '모두',
        tn: '트랜스링크 뉴스',
        pn: '포트폴리오 뉴스',
        event: '이벤트',
      },
      sort: {
        newest: '최신순',
        popular: '인기순',
      },
      news: {},
      content: {
        playtime: '재생시간'
      },
      footer: {
        address: '서울시 강남구 선릉로 551, 5F, 06145',
      },
    }
  }
}

const i18n = new VueI18n(
    {
      locale: 'ko',
      messages
    }
)

new Vue({
  i18n,
  router,
  render: h => h(App)
}).$mount('#app');

Vue.filter('formatNumber', function(number) {
  return common.numberWithCommas(number);
});
