var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      staticClass: "portfolio",
      class: { hasLink: _vm.hasServiceLink },
      on: { click: _vm.portfolioClicked }
    },
    [
      _c("div", { staticClass: "logo-wrapper" }, [
        _c("img", {
          staticClass: "logo",
          attrs: { src: _vm.portfolio.logo_image_url }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }