<template>
  <a :href="newsItem.link_url" target="_blank">
    <li class="news-item">
      <div class="name-wrapper">
        <span class="media-name">{{newsItem.media_name}}</span>
        <span class="published_at">{{newsItem.published_at}}</span>
      </div>

      <p class="title type-news-title ellipsis cut-2">
        {{newsItem.title}}
      </p>

      <div class="content type-news-content ellipsis cut-3">
        {{newsItem.content}}
      </div>

      <div class="more type-news-more">
        <span>Read more</span> <img src="http://assets.translink.kr/commons/arrow_forward.svg" class="icon" />
      </div>
    </li>
  </a>
</template>

<script>
export default {
  props: {
    newsItem: {
      type: Object,
      default: {},
    },
    hasServiceLink: {
      type: Boolean,
      default: true
    }
  },
  methods: {
  }
}
</script>