var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-mask" }, [
    _c("div", { staticClass: "modal-wrapper" }, [
      _c("div", { staticClass: "modal-container" }, [
        _c("div", { staticClass: "modal-header" }, [
          _c("div", { staticClass: "close-wrapper" }, [
            _c("img", {
              staticClass: "close-btn",
              attrs: {
                src:
                  "https://s3.ap-northeast-2.amazonaws.com/assets.translink/commons/close.png"
              },
              on: { click: _vm.closePortfolio }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "logo-wrapper" }, [
            _c("img", {
              staticClass: "logo",
              attrs: { src: _vm.portfolio.logo_image_url }
            })
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "modal-body" }, [
          _c("div", { staticClass: "description-wrapper" }, [
            _c("p", { staticClass: "desc" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.portfolio.details[0].description) +
                  "\n          "
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "detail-wrapper" }, [
            _c("div", { staticClass: "details" }, [
              _c("div", { staticClass: "detail" }, [
                _c("p", { staticClass: "name" }, [_vm._v("Location")]),
                _vm._v(" "),
                _c("p", { staticClass: "val" }, [
                  _vm._v(_vm._s(_vm.portfolio.details[0].location))
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "detail" }, [
                _c("p", { staticClass: "name" }, [_vm._v("Website")]),
                _vm._v(" "),
                _c("p", { staticClass: "val link" }, [
                  _c(
                    "a",
                    {
                      staticStyle: { color: "#fff" },
                      attrs: {
                        href: _vm.portfolio.website_url,
                        target: "_blank"
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.portfolio.website_url
                            .replaceAll("https://", "")
                            .replaceAll("http://", "")
                        )
                      )
                    ]
                  )
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "details" }, [
              _c("div", { staticClass: "detail" }, [
                _c("p", { staticClass: "name" }, [_vm._v("Year invested")]),
                _vm._v(" "),
                _c("p", { staticClass: "val" }, [
                  _vm._v(_vm._s(_vm.portfolio.invested))
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "detail" },
                [
                  _c("p", { staticClass: "name" }, [_vm._v("Sector")]),
                  _vm._v(" "),
                  _vm._l(_vm.portfolio.sectors, function(sector) {
                    return _c("p", { key: sector.id, staticClass: "val" }, [
                      _vm._v(_vm._s(sector.name))
                    ])
                  })
                ],
                2
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "details" }, [
              _c("div", { staticClass: "detail" }, [
                _c("p", { staticClass: "name" }, [_vm._v("Investment team")]),
                _vm._v(" "),
                _c("p", { staticClass: "val" }, [
                  _vm._v(_vm._s(_vm.portfolio.details[0].invest_team))
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "detail" }, [
                _c("p", { staticClass: "name" }, [_vm._v("Investment point")]),
                _vm._v(" "),
                _c("p", { staticClass: "val" }, [
                  _vm._v(_vm._s(_vm.portfolio.details[0].point))
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }