<template>
  <div class="portfolio-list">
    <portfolio-item :portfolio="portfolio" v-for="portfolio in portfolios" :key="portfolio.id" @selected="selected" />
  </div>
</template>

<script>
import PortfolioItem from "./portfolio";

export default {
  props: {
    portfolios: {
      type: Array,
      default: [],
    }
  },
  data() {
    return {
    }
  },
  components: {
    PortfolioItem,
  },
  methods: {
    selected(selectedId) {
      this.$emit("selectedPortfolio", selectedId);
    }
  }
}
</script>