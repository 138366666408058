<template>
  <div class="categories">
    <div class="category" @click="selected(0)" :class="{active: selectedCategory == 0}">
      <span v-if="hasLanguage">{{$t('message.categories.all')}}</span>
      <span v-else>All</span>
    </div>

    <div class="category" @click="selected(category.id)" v-for="category in categories" :class="{active: selectedCategory == category.id}">
      <span v-if="hasLanguage">{{$t(category.name)}}</span>
      <span v-else>{{category.name}}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hasLanguage: {
      type: Boolean,
      default: false,
    },
    categories: {
      type: Array,
      default: [],
    },
    selectedCategory: {
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
    }
  },
  methods: {
    selected(categoryId) {
      this.$emit("selected", categoryId);
    }
  }
}
</script>