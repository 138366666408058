<template>
  <div>
    <div class="contents-list">
      <div v-for="content in contents" @click="$emit('itemClicked', content)" @key="content.id">
        <div class="list-item">
          <div class="image-wrapper">
            <div class="img-wrapper"></div>
            <img :src="content.thumbnail_image_url" class="image" />
          </div>

          <div class="text-wrapper">
            <div class="title-wrapper">
              <p class="title type-content-title2 ellipsis cut-2">
                {{content.title}}
              </p>
            </div>

            <div class="author-wrapper type-content-default">
            <span class="author">
              {{content.content_type == 'video' ? `${$t('message.content.playtime')} ${playtime(content)}` : content.author}}·
            </span>

              <span class="published-at">
              {{content.published_at}}
            </span>
            </div>

            <div class="body-wrapper type-content-default2 ellipsis cut-2">
              {{content.body}}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="more-wrapper" v-if="hasMore">
      <div class="more-btn" @click="loadMore">
        Load more
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    contents: {
      type: Array,
      default: [],
    },
    hasMore: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
    }
  },
  methods: {
    loadMore() {
      this.$emit('loadMore');
    },
    playtime(featuredItem) {
      const hour = Math.floor(featuredItem.duration / 3600);
      const min = Math.floor((featuredItem.duration - (hour * 3600)) / 60);
      const second = featuredItem.duration - (hour * 3600) - (min * 60);

      if (hour > 0) {
        return `${(hour + '').padStart(2, '0')}:${(min + '').padStart(2, '0')}:${(second + '').padStart(2, '0')}`
      }

      return `${(min + '').padStart(2, '0')}:${(second + '').padStart(2, '0')}`
    }
  }
}
</script>