var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "about" }, [
    _c("section", { staticClass: "about-hero section-container" }, [
      _c("div", { staticClass: "content-container" }, [
        _c("div", { staticClass: "text-wrapper" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("p", { staticClass: "type-d3 section-description1" }, [
            _vm._v(
              "\n          " +
                _vm._s(_vm.$t("message.about.section1")) +
                "\n        "
            )
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "type-d4 section-description2" }, [
            _vm._v(
              "\n          " +
                _vm._s(_vm.$t("message.about.section1d")) +
                "\n\n        "
            )
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("section", { staticClass: "about-benefit section-container" }, [
      _c("div", { staticClass: "content-container" }, [
        _c("p", { staticClass: "type-t2" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.$t("message.about.section2_title")) +
              "\n      "
          )
        ]),
        _vm._v(" "),
        _vm._m(1),
        _vm._v(" "),
        _c("div", { staticClass: "description-wrapper" }, [
          _c("p", { staticClass: "desc type-d4" }, [
            _vm._v(
              "\n          " +
                _vm._s(_vm.$t("message.about.section2")) +
                "\n        "
            )
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "desc type-d4" }, [
            _vm._v(
              "\n          " +
                _vm._s(_vm.$t("message.about.section2d")) +
                "\n        "
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "h1",
      { staticClass: "type-t3 section-title", staticStyle: { color: "#fff" } },
      [_c("span", { staticClass: "break" }, [_vm._v("About")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "industry-wrapper" }, [
      _c("div", { staticClass: "industries" }, [
        _c("div", { staticClass: "industry" }, [_c("span", [_vm._v("SaaS")])]),
        _vm._v(" "),
        _c("div", { staticClass: "industry" }, [_c("span", [_vm._v("CPG")])]),
        _vm._v(" "),
        _c("div", { staticClass: "industry" }, [
          _c("span", [_vm._v("Deep tech")])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "industry" }, [
          _c("span", [_vm._v("Content")])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }