<template>
  <header :class="{nav: true, white: isWhite}">
    <div class="nav-wrapper">
      <router-link :to="{name: 'home'}" class="logo-wrapper remove-line-height" >
        <img :src="logoImage" class="logo" />
      </router-link>
      <div class="side-menus">
        <div class="menus-activator mobile-only">
          <button class="button button-hamburger" @click="activeMobileMenu">
            <div class="item"></div>
            <div class="item"></div>
            <div class="item"></div>
          </button>
        </div>
      </div>

      <div class="invisible" :class="activeMobile" @click="closeMobileMenu"></div>

      <div class="menus-wrapper" :class="activeMobile">
        <ul class="menus">
          <li class="menu">
            <div :class="{item: true, active: current == 'about'}">
              <router-link :to="{name: 'aboutIndex'}" class="name">
                <span>About</span>
              </router-link>
            </div>
          </li>
          <li class="menu">
            <div :class="{item: true, active: current == 'team'}">
              <router-link :to="{name: 'teamIndex'}" class="name">
                <span>Team</span>
              </router-link>
            </div>
          </li>
          <li class="menu">
            <div :class="{item: true, active: current == 'portfolio'}">
              <router-link :to="{name: 'portfolioIndex'}" class="name">
                <span>Portfolio</span>
              </router-link>
            </div>
          </li>
          <li class="menu">
            <div :class="{item: true, active: current == 'news'}">
              <router-link :to="{name: 'newsIndex'}" class="name">
                <span>News & Events</span>
              </router-link>
            </div>
          </li>
          <li class="menu">
            <div :class="{item: true, active: current == 'contents'}">
              <router-link :to="{name: 'contentIndex'}" class="name">
                <span>Content</span>
              </router-link>
            </div>
          </li>
        </ul>

        <ul class="menus lang">
          <li class="menu">
            <div :class="{item: true, active: isKorean}" @click="changeLang('ko')">
              <span class="name">KOR</span>
            </div>
          </li>

          <li class="menu">
            <div :class="{item: true, active: isEnglish}" @click="changeLang('en')">
              <span class="name">ENG</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </header>
</template>

<script>
import {EventBus} from "../util/event-bus";

export default {
  props:  [],
  data() {
    return {
      isWhite: false,
      cachedWhite: false,
      current: [],
      activeMobile: '',
      lang: 'ko',
    }
  },
  computed: {
    logoImage() {
      if (this.isWhite) {
        return "https://s3.ap-northeast-2.amazonaws.com/assets.translink/top/logo.svg";
      }

      return "https://assets.translink.kr/commons/logo_w.svg";
    },
    isKorean() {
      return this.lang == 'ko';
    },
    isEnglish() {
      return this.lang == 'en';
    }
  },
  created() {
    let lang = 'ko';
    if (!this.$cookies.isKey('translink-lang')) {
      const userLang = navigator.language || navigator.userLanguage;
      if (userLang && userLang.toLowerCase() != 'ko') {
        lang = 'en';
      }

      this.$cookies.set('translink-lang', 'ko');
    } else {
      lang = this.$cookies.get('translink-lang');
    }

    this.$i18n.locale = lang;
    this.lang = lang;

    window.addEventListener('scroll', this.handleScroll);
    if (this.$route.meta.current) {
      this.current = this.$route.meta.current;

      if (this.current.filter(x => ["team", "portfolio", "news", "contents"].includes(x)).length > 0) {
        this.isWhite = true;
      } else {
        this.isWhite = false;
      }
    } else {
      this.current = [];
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    closeMobileMenu() {
      this.activeMobile = '';
      this.isWhite = this.cachedWhite;
    },
    handleScroll (event) {
      if (window.scrollY == 0 && this.current.filter(x => ["team", "portfolio", "news", "contents"].includes(x)).length == 0) {
        this.isWhite = false;
      } else {
        this.isWhite = true;
      }
    },
    activeMobileMenu: function() {
      if (this.activeMobile == 'active') {
        this.activeMobile = '';
        this.isWhite = this.cachedWhite;
      } else {
        this.activeMobile = 'active';
        this.cachedWhite = this.isWhite;
        this.isWhite = true;
      }
    },
    changeLang(lang) {
      if (this.lang == lang) {
        return;
      }

      this.lang = lang;
      this.$cookies.set('translink-lang', lang);
      this.$i18n.locale = lang;
      EventBus.$emit("langChanged", lang);
    }
  },
  mounted() {
  },
  watch: {
    $route(to, from) {
      this.activeMobile = '';
      if (to.meta.current) {
        this.current = to.meta.current;

        if (this.current.filter(x => ["team", "portfolio", "news", "contents"].includes(x)).length > 0) {
          this.isWhite = true;
        } else {
          this.isWhite = false;
        }
      } else {
        this.current = [];
        this.isWhite = false;
      }
    }
  }
}
</script>