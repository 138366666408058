var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", { staticClass: "footer section-container" }, [
    _c("div", { staticClass: "content-container" }, [
      _c("div", { staticClass: "link-wrapper" }, [
        _c("div", { staticClass: "company-info" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "footer-sub-content" }, [
            _c("span", { staticClass: "address" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$t("message.footer.address")) +
                  "\n          "
              )
            ])
          ]),
          _vm._v(" "),
          _vm._m(1)
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "site-map" }, [
          _vm._m(2),
          _vm._v(" "),
          _c("ul", { staticClass: "maps" }, [
            _c("li", { staticClass: "menu" }, [
              _c(
                "div",
                { staticClass: "item" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "name",
                      attrs: { to: { name: "aboutIndex" } }
                    },
                    [_c("span", [_vm._v("About")])]
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "menu" }, [
              _c(
                "div",
                { staticClass: "item" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "name",
                      attrs: { to: { name: "teamIndex" } }
                    },
                    [_c("span", [_vm._v("Team")])]
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "menu" }, [
              _c(
                "div",
                { staticClass: "item" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "name",
                      attrs: { to: { name: "portfolioIndex" } }
                    },
                    [_c("span", [_vm._v("Portfolio")])]
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "menu" }, [
              _c(
                "div",
                { staticClass: "item" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "name",
                      attrs: { to: { name: "newsIndex" } }
                    },
                    [_c("span", [_vm._v("News & Event")])]
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "menu" }, [
              _c(
                "div",
                { staticClass: "item" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "name",
                      attrs: { to: { name: "contentIndex" } }
                    },
                    [_c("span", [_vm._v("Content")])]
                  )
                ],
                1
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _vm._m(3)
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "copyright-wrapper" }, [
        _c("div", { staticClass: "copyright" }, [
          _vm._v(
            "\n        ©" +
              _vm._s(_vm.year) +
              " Translink Investment, Corp. All rights are reserved.\n      "
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer-sub-title" }, [
      _c("span", { staticClass: "name" }, [_vm._v("Translink Investment")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "sns-wrapper" }, [
      _c("div", { staticClass: "item" }, [
        _c(
          "a",
          {
            attrs: {
              href: "https://www.facebook.com/TranslinkInvestment",
              target: "_blank"
            }
          },
          [
            _c("img", {
              staticClass: "sns",
              attrs: {
                src:
                  "https://s3.ap-northeast-2.amazonaws.com/assets.translink/footer/facebook.svg"
              }
            })
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item" }, [
        _c(
          "a",
          {
            attrs: {
              href: "https://www.linkedin.com/company/translink-investment",
              target: "_blank"
            }
          },
          [
            _c("img", {
              staticClass: "sns",
              attrs: {
                src:
                  "https://s3.ap-northeast-2.amazonaws.com/assets.translink/footer/linkedin.svg"
              }
            })
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item" }, [
        _c(
          "a",
          {
            attrs: {
              href: "https://www.youtube.com/channel/UC1yWMxMin6g7wW2r_vMI38w",
              target: "_blank"
            }
          },
          [
            _c("img", {
              staticClass: "sns",
              attrs: {
                src:
                  "https://s3.ap-northeast-2.amazonaws.com/assets.translink/footer/youtube.svg"
              }
            })
          ]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer-sub-title" }, [
      _c("span", { staticClass: "name" }, [_vm._v("Company")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "contact" }, [
      _c("div", { staticClass: "footer-sub-title" }, [
        _c("span", { staticClass: "name" }, [_vm._v("Contact Us")])
      ]),
      _vm._v(" "),
      _c("ul", { staticClass: "contact-list" }, [
        _c("li", { staticClass: "menu" }, [
          _c("div", { staticClass: "item" }, [
            _c(
              "a",
              {
                staticClass: "name mail",
                attrs: { href: "mailto:info@translink.kr" }
              },
              [_c("span", [_vm._v("Email: info@translink.kr")])]
            )
          ])
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "menu" }, [
          _c("div", { staticClass: "item" }, [
            _c("span", { staticClass: "name tel" }, [
              _vm._v("\n                Tel: 02-558-3501\n              ")
            ])
          ])
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "menu" }, [
          _c("div", { staticClass: "item" }, [
            _c("span", { staticClass: "name tel" }, [
              _vm._v("\n                Fax: 02-6008-5028\n              ")
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }