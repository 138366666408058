<template>
  <div class="contents-featured-list">
    <div v-for="featuredItem in featuredItems" @click="$emit('itemClicked', featuredItem)" @key="featuredItem.id">
      <div class="featured-item">
        <div class="image-wrapper">
          <div class="img-wrapper"></div>
          <img :src="featuredItem.thumbnail_image_url" class="image" />
        </div>

        <div class="text-wrapper">
          <div class="title-wrapper">
            <p class="title type-content-title">
              {{featuredItem.title}}
            </p>
          </div>

          <div class="author-wrapper type-content-default">
            <span class="author">
              {{featuredItem.content_type == 'video' ? `${$t('message.content.playtime')} ${playtime(featuredItem)}` : featuredItem.author}}·
            </span>

            <span class="published-at">
              {{featuredItem.published_at}}
            </span>
          </div>

          <div class="body-wrapper type-content-default3 ellipsis cut-2">
            {{featuredItem.body}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    featuredItems: {
      type: Array,
      default: [],
    }
  },
  data() {
    return {
    }
  },
  methods: {
    playtime(featuredItem) {
      const hour = Math.floor(featuredItem.duration / 3600);
      const min = Math.floor((featuredItem.duration - (hour * 3600)) / 60);
      const second = featuredItem.duration - (hour * 3600) - (min * 60);

      if (hour > 0) {
        return `${(hour + '').padStart(2, '0')}:${(min + '').padStart(2, '0')}:${(second + '').padStart(2, '0')}`
      }

      return `${(min + '').padStart(2, '0')}:${(second + '').padStart(2, '0')}`
    }
  }
}
</script>