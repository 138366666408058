<template>
  <div class="scroll">
    <ul class="home-news">
      <news-item :has-service-link="hasServiceLink" :newsItem="newsItem" v-for="newsItem in news" :key="newsItem.id">
      </news-item>
    </ul>
  </div>
</template>

<script>
import NewsItem from "./news_item";

export default {
  props: {
    news: {
      type: Array,
      default: []
    },
    hasServiceLink: {
      type: Boolean,
      default: true
    }
  },
  components: {
    NewsItem,
  }
}
</script>