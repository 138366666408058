var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "contents-list" },
      _vm._l(_vm.contents, function(content) {
        return _c(
          "div",
          {
            on: {
              click: function($event) {
                return _vm.$emit("itemClicked", content)
              },
              key: content.id
            }
          },
          [
            _c("div", { staticClass: "list-item" }, [
              _c("div", { staticClass: "image-wrapper" }, [
                _c("div", { staticClass: "img-wrapper" }),
                _vm._v(" "),
                _c("img", {
                  staticClass: "image",
                  attrs: { src: content.thumbnail_image_url }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "text-wrapper" }, [
                _c("div", { staticClass: "title-wrapper" }, [
                  _c(
                    "p",
                    { staticClass: "title type-content-title2 ellipsis cut-2" },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(content.title) +
                          "\n            "
                      )
                    ]
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "author-wrapper type-content-default" },
                  [
                    _c("span", { staticClass: "author" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            content.content_type == "video"
                              ? _vm.$t("message.content.playtime") +
                                  " " +
                                  _vm.playtime(content)
                              : content.author
                          ) +
                          "·\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "published-at" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(content.published_at) +
                          "\n          "
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "body-wrapper type-content-default2 ellipsis cut-2"
                  },
                  [
                    _vm._v(
                      "\n            " + _vm._s(content.body) + "\n          "
                    )
                  ]
                )
              ])
            ])
          ]
        )
      }),
      0
    ),
    _vm._v(" "),
    _vm.hasMore
      ? _c("div", { staticClass: "more-wrapper" }, [
          _c("div", { staticClass: "more-btn", on: { click: _vm.loadMore } }, [
            _vm._v("\n      Load more\n    ")
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }