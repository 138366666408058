<template>
  <section class="landing">
    <section class="landing-hero section-container">
      <div class="content-container">
        <div class="text-wrapper">
          <h1 class="type-t1 section-title" style="color: #fff">
            <span class="break">Venture investment</span>
            for going global
          </h1>

          <p class="type-d1 section-description">
            {{$t('message.home.section1')}}
          </p>

          <div class="button-wrapper">
            <router-link :to="{name: 'aboutIndex'}" class="button button-bordered type-b1" >
              <span> {{$t('message.home.learn')}} </span>
            </router-link>
          </div>
        </div>
      </div>
    </section>

    <section class="landing-portfolio section-container">
      <div class="content-container">
        <p class="type-t2">
          Our portfolio
        </p>

        <landing-portfolio :portfolios="portfolios" :has-service-link=false @portfolioClicked="portfolioClicked" />

        <div class="button-wrapper">
          <router-link :to="{name: 'portfolioIndex'}" class="button button-bordered button-bordered-main type-b1">
            <span>{{$t('message.home.vp')}}</span>
          </router-link>
        </div>
      </div>
    </section>

    <section class="landing-team section-container">
      <div class="content-container">
        <p class="type-t2">
          Team
        </p>

        <div class="description-wrapper type-d2">
          {{$t('message.home.section2')}}
        </div>

        <div class="button-wrapper">
          <router-link :to="{name: 'teamIndex'}" class="button button-bordered type-b1">
            <span>{{$t('message.home.vm')}}</span>
          </router-link>
        </div>
      </div>
    </section>

    <section class="landing-news section-container">
      <div class="content-container">
        <p class="type-t2">
          Featured News & Events
        </p>

        <landing-news :news="news" :has-service-link=true />

        <div class="button-wrapper">
          <router-link :to="{name: 'newsIndex'}" class="button button-bordered button-bordered-main type-b1">
            <span>{{$t('message.home.vn')}}</span>
          </router-link>
        </div>
      </div>
    </section>

    <portfolio-detail :portfolio="portfolio" v-if="showDetail && portfolio != null" @closeModal="closeModal" />
  </section>
</template>

<script>
import LandingPortfolio from "../../commons/portfolios/landings";
import LandingNews from "../../commons/news/landings";
import PortfolioDetail from "./portfolio/show";
import {EventBus} from "../util/event-bus";

export default {
  props: {
  },
  data() {
    return {
      portfolios: [],
      news: [],
      portfolio: null,
      showDetail: false,
    }
  },
  components: {
    LandingPortfolio, LandingNews, PortfolioDetail
  },
  mounted() {
    this.getPortfolios();
    this.getNews();

    EventBus.$on("langChanged", (lang) => {
      this.getPortfolios();
      this.getNews();
    });
  },
  methods: {
    closeModal() {
      this.portfolio = null;
      this.showDetail = false;
    },
    portfolioClicked(portfolio) {
      this.portfolio = portfolio;
      this.showDetail = true;
    },
    getPortfolios() {
      axios.get('/service/api/portfolios/home').then(res => {
        this.portfolios = res.data.portfolios;
      });
    },
    getNews() {
      axios.get('/service/api/news/home').then(res => {
        this.news = res.data.news;
      });
    },
  }
}
</script>