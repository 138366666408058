<template>
  <div class="member" @click="clickMember">
    <div class="profile-wrapper">
      <div class="img-wrapper"></div>
      <img :src="member.profile_image_url" class="profile-img" />
    </div>

    <div class="name-wrapper">
      <p class="name">
        {{getName}}
      </p>
      <p class="position">
        {{getPosition}}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    member: {
      type: Object,
      default: {},
    },
    hasServiceLink: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      lang: this.$cookies.get('translink-lang'),
    }
  },
  computed: {
    getName() {
      if (this.lang == 'ko') {
        return this.member.ko_name;
      }

      return this.member.en_name;
    },
    getPosition() {
      if (this.lang == 'ko') {
        return this.member.ko_position;
      }

      return this.member.en_position;
    }
  },
  methods: {
    clickMember() {
      if (this.hasServiceLink) {
        this.$router.push({name: 'teamShow', params: {id: this.member.id}});
      }
    }
  }
}
</script>