<template>
  <div class="portfolio" @click="selected">
    <div class="logo-wrapper">
      <img :src="portfolio.logo_image_url" class="logo" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    portfolio: {
      type: Object,
      default: {},
    }
  },
  data() {
    return {
    }
  },
  methods: {
    selected() {
      this.$emit('selected', this.portfolio.id);
    }
  }
}
</script>