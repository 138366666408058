var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "contents-featured-list" },
    _vm._l(_vm.featuredItems, function(featuredItem) {
      return _c(
        "div",
        {
          on: {
            click: function($event) {
              return _vm.$emit("itemClicked", featuredItem)
            },
            key: featuredItem.id
          }
        },
        [
          _c("div", { staticClass: "featured-item" }, [
            _c("div", { staticClass: "image-wrapper" }, [
              _c("div", { staticClass: "img-wrapper" }),
              _vm._v(" "),
              _c("img", {
                staticClass: "image",
                attrs: { src: featuredItem.thumbnail_image_url }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "text-wrapper" }, [
              _c("div", { staticClass: "title-wrapper" }, [
                _c("p", { staticClass: "title type-content-title" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(featuredItem.title) +
                      "\n          "
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "author-wrapper type-content-default" },
                [
                  _c("span", { staticClass: "author" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          featuredItem.content_type == "video"
                            ? _vm.$t("message.content.playtime") +
                                " " +
                                _vm.playtime(featuredItem)
                            : featuredItem.author
                        ) +
                        "·\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "published-at" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(featuredItem.published_at) +
                        "\n          "
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "body-wrapper type-content-default3 ellipsis cut-2"
                },
                [
                  _vm._v(
                    "\n          " + _vm._s(featuredItem.body) + "\n        "
                  )
                ]
              )
            ])
          ])
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }