var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "news" }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "news-featured-container section-container" },
      [
        _c(
          "div",
          { staticClass: "content-container" },
          [
            _c("p", { staticClass: "type-t2" }, [
              _vm._v("\n        Featured News & Events\n      ")
            ]),
            _vm._v(" "),
            _c("landing-news", {
              attrs: { news: _vm.featuredNews, "has-service-link": true }
            })
          ],
          1
        )
      ]
    ),
    _vm._v(" "),
    _c("section", { staticClass: "news-list-container section-container" }, [
      _c(
        "div",
        { staticClass: "content-container" },
        [
          _c(
            "div",
            { staticClass: "scroll" },
            [
              _c("category-list", {
                attrs: {
                  categories: _vm.categories,
                  selectedCategory: _vm.selectedCategory,
                  hasLanguage: true
                },
                on: { selected: _vm.selected }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("news-list", {
            attrs: { news: _vm.news, hasMore: _vm.hasMore },
            on: { loadMore: _vm.loadMore }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "news-hero section-container" }, [
      _c("div", { staticClass: "content-container" }, [
        _c("div", { staticClass: "text-wrapper" }, [
          _c("h1", { staticClass: "type-t3 section-title" }, [
            _c("span", { staticClass: "break" }, [_vm._v("News & Events")])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }