import Vue from 'vue';

function isObject (value) {
  return value === Object(value)
}

function isArray (value) {
  return Array.isArray(value)
}

function isFile (value) {
  return value instanceof File
}

export default {
  cleanup(object, excludedKeys) {
    return _.omitBy(object,
      (value, key) => { return _.includes(excludedKeys, key) || _.isNull(value) }
    );
  },
  cleanupCollection(collection, excludedKeys) {
    return _.map(collection, item => this.cleanup(item, excludedKeys))
  },
  queryParams() {
    return document.location.search.replace(/(^\?)/,'').split("&").map(function(n){return n = n.split("="),this[n[0]] = decodeURIComponent(n[1]),this}.bind({}))[0];
  },
  objectToFormData(obj, fd, pre) {
    fd = fd || new FormData();
    let that = this;

    Object.keys(obj).forEach(function (prop) {
      let key = pre ? (pre + '[' + prop + ']') : prop;

      if (isObject(obj[prop]) && !isArray(obj[prop]) && !isFile(obj[prop])) {
        that.objectToFormData(obj[prop], fd, key)
      } else if (isArray(obj[prop])) {
        obj[prop].forEach(function (value, index) {
          if (isObject(value) && !isFile(value)) {
            let objectKey = key + '[' + index + ']';
            that.objectToFormData(value, fd, objectKey)
          } else {
            let arrayKey = key + '[]';
            fd.append(arrayKey, value)
          }
        })
      } else {
        fd.append(key, obj[prop])
      }
    });

    return fd
  },
  formTitle(objectId, name, defaultTitle) {
    const trimmedName = _.trim(name);

    if (objectId) {
      return `#${objectId} ${trimmedName}`;
    } else {
      return trimmedName || defaultTitle;
    }
  },
  truncate(str, max) {
    if (!str) {
      return ''
    }

    return str.length > max ? str.substr(0, max - 1) + '…' : str;
  },
  numberWithCommas(x) {
    const num = Math.round(x * 100) / 100;

    if (num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return '0';
    }
  },
  mountComponent(targetElementId, component) {
    const targetElement = document.getElementById(targetElementId);
    if (targetElement) {
      const props = JSON.parse(targetElement.getAttribute('data'));

      new Vue({
        render: h => h(component, {
          props
        })
      }).$mount(targetElement);
    }
  },
  dateToString(date) {
    return date.getFullYear() + "." + (date.getMonth() + 1) + "." + date.getDate();
  },
  intToDateString(intDate) {
    let d = new Date(intDate * 1000);
    return d.toDateString();
  }
}
