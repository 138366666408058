<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">

        <div class="modal-header">
          <div class="close-wrapper">
            <img src="https://s3.ap-northeast-2.amazonaws.com/assets.translink/commons/close.png" @click="closePortfolio" class="close-btn" />
          </div>
          <div class="logo-wrapper">
            <img :src="portfolio.logo_image_url" class="logo" />
          </div>
        </div>

        <div class="modal-body">
          <div class="description-wrapper">
            <p class="desc">
              {{portfolio.details[0].description}}
            </p>
          </div>

          <div class="detail-wrapper">
            <div class="details">
              <div class="detail">
                <p class="name">Location</p>
                <p class="val">{{portfolio.details[0].location}}</p>
              </div>
              <div class="detail">
                <p class="name">Website</p>
                <p class="val link"><a :href="portfolio.website_url" target="_blank" style="color:#fff">{{portfolio.website_url.replaceAll("https://", "").replaceAll("http://", "")}}</a></p>
              </div>
            </div>

            <div class="details">
              <div class="detail">
                <p class="name">Year invested</p>
                <p class="val">{{portfolio.invested}}</p>
              </div>
              <div class="detail">
                <p class="name">Sector</p>
                <p class="val" v-for="sector in portfolio.sectors" :key="sector.id">{{sector.name}}</p>
              </div>
            </div>

            <div class="details">
              <div class="detail">
                <p class="name">Investment team</p>
                <p class="val">{{portfolio.details[0].invest_team}}</p>
              </div>
              <div class="detail">
                <p class="name">Investment point</p>
                <p class="val">{{portfolio.details[0].point}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    portfolio: {
      type: Object,
      default: {},
    }
  },
  mounted() {
    console.log(this.portfolio);
  },
  data() {
    return {
    }
  },
  methods: {
    closePortfolio() {
      this.$emit('closeModal');
    }
  }
}
</script>