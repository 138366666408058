var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "contents" }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "contents-featured-container section-container" },
      [
        _c(
          "div",
          { staticClass: "content-container" },
          [
            _c("p", { staticClass: "type-t2" }, [
              _vm._v("\n        Featured content\n      ")
            ]),
            _vm._v(" "),
            _c("featured-list", {
              attrs: { featuredItems: _vm.featuredLists },
              on: { itemClicked: _vm.itemClicked }
            })
          ],
          1
        )
      ]
    ),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "contents-list-container section-container" },
      [
        _c(
          "div",
          { staticClass: "content-container" },
          [
            _c("div", { staticClass: "list-header" }, [
              _c("p", { staticClass: "type-t2 header-title" }, [
                _vm._v("\n          All content\n        ")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "sort-types" }, [
                _c(
                  "div",
                  {
                    staticClass: "type",
                    class: { active: _vm.sortType == 0 },
                    on: {
                      click: function($event) {
                        return _vm.reloadContent(0)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("message.sort.newest")) +
                        "\n          "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "type",
                    class: { active: _vm.sortType == 1 },
                    on: {
                      click: function($event) {
                        return _vm.reloadContent(1)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("message.sort.popular")) +
                        "\n          "
                    )
                  ]
                )
              ])
            ]),
            _vm._v(" "),
            _c("content-list", {
              attrs: { contents: _vm.contents, "has-more": _vm.hasMore },
              on: { loadMore: _vm.loadMore, itemClicked: _vm.itemClicked }
            })
          ],
          1
        )
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "video-modal-wrapper" },
      [
        _c(
          "a-modal",
          {
            attrs: {
              footer: null,
              width: "1083px",
              centered: "",
              dialogClass: "video-modal",
              closable: false
            },
            on: { cancel: _vm.closeModal },
            model: {
              value: _vm.isModalVisible,
              callback: function($$v) {
                _vm.isModalVisible = $$v
              },
              expression: "isModalVisible"
            }
          },
          [
            _c("iframe", {
              ref: "video",
              staticClass: "video",
              attrs: {
                src: _vm.videoSrcLink,
                title: "YouTube video player",
                frameborder: "0",
                allow:
                  "accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; ",
                allowfullscreen: "allowfullscreen"
              }
            })
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "contents-hero section-container" }, [
      _c("div", { staticClass: "content-container" }, [
        _c("div", { staticClass: "text-wrapper" }, [
          _c("h1", { staticClass: "type-t3 section-title" }, [
            _c("span", { staticClass: "break" }, [_vm._v("Content")])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }