var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "home-portfolios" },
    _vm._l(_vm.portfolios, function(portfolio) {
      return _c("portfolio-item", {
        key: portfolio.id,
        attrs: { "has-service-link": _vm.hasServiceLink, portfolio: portfolio },
        on: {
          portfolioClicked: function(p) {
            _vm.$emit("portfolioClicked", p)
          }
        }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }