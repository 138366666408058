<template>
  <li class="portfolio" :class="{hasLink: hasServiceLink}" @click="portfolioClicked">
    <div class="logo-wrapper">
      <img :src="portfolio.logo_image_url" class="logo" />
    </div>
  </li>
</template>

<script>
export default {
  props: {
    portfolio: {
      type: Object,
      default: {},
    },
    hasServiceLink: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    portfolioClicked() {
      this.$emit("portfolioClicked", this.portfolio);
      if (this.hasServiceLink) {
        this.$router.push({path: `/portfolios/${this.portfolio.id}`});
      }
    }
  }
}
</script>