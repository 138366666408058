var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a", { attrs: { href: _vm.newsItem.link_url, target: "_blank" } }, [
    _c("li", { staticClass: "news-item" }, [
      _c("div", { staticClass: "name-wrapper" }, [
        _c("span", { staticClass: "media-name" }, [
          _vm._v(_vm._s(_vm.newsItem.media_name))
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "published_at" }, [
          _vm._v(_vm._s(_vm.newsItem.published_at))
        ])
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "title type-news-title ellipsis cut-2" }, [
        _vm._v("\n      " + _vm._s(_vm.newsItem.title) + "\n    ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "content type-news-content ellipsis cut-3" }, [
        _vm._v("\n      " + _vm._s(_vm.newsItem.content) + "\n    ")
      ]),
      _vm._v(" "),
      _vm._m(0)
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "more type-news-more" }, [
      _c("span", [_vm._v("Read more")]),
      _vm._v(" "),
      _c("img", {
        staticClass: "icon",
        attrs: { src: "http://assets.translink.kr/commons/arrow_forward.svg" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }