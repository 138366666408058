var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "portfolio" },
    [
      _c("section", { staticClass: "portfolio-hero section-container" }, [
        _c("div", { staticClass: "content-container" }, [
          _c("div", { staticClass: "text-wrapper" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("p", { staticClass: "type-d5 section-description1" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("message.portfolio.section1")) +
                  "\n        "
              )
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "portfolio-list-container section-container" },
        [
          _c(
            "div",
            { staticClass: "content-container" },
            [
              _c(
                "div",
                { staticClass: "scroll" },
                [
                  _c("category-list", {
                    attrs: {
                      categories: _vm.categories,
                      selectedCategory: _vm.selectedCategory
                    },
                    on: { selected: _vm.selected }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("portfolio-list", {
                attrs: { portfolios: _vm.portfolios },
                on: { selectedPortfolio: _vm.selectedPortfolio }
              })
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _vm.showDetail && _vm.portfolio != null
        ? _c("portfolio-detail", {
            attrs: { portfolio: _vm.portfolio },
            on: { closeModal: _vm.closeModal }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h1", { staticClass: "type-t4 section-title" }, [
      _c("span", { staticClass: "break" }, [_vm._v("Portfolio")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }