import Vue from 'vue'
import VueRouter from 'vue-router';
Vue.use(VueRouter);

import HomeIndex from './pages/home';

import AboutIndex from './pages/about/';

import TeamIndex from './pages/team/';
import TeamShow from './pages/team/show';

import PortfolioIndex from './pages/portfolio/';
import PortfolioShow from './pages/portfolio/show';

import NewsIndex from './pages/news/';

import ContentIndex from './pages/content/';

const router = new VueRouter({
  mode: 'history',
  routes: [
    { path: '/', component: HomeIndex, name: 'home', meth: {current: ['home']} },

    { path: '/about', component: AboutIndex, name: 'aboutIndex', meta: {current: ['about']} },

    { path: '/teams', component: TeamIndex, name: 'teamIndex', meta: {current: ['team']} },
    { path: '/teams/:id', component: TeamShow, name: 'teamShow', meta: {current: ['team']} },

    { path: '/portfolios', component: PortfolioIndex, name: 'portfolioIndex', meta: {current: ['portfolio']} },
    { path: '/portfolios/:id', component: PortfolioShow, name: 'portfolioShow', meta: {current: ['portfolio']} },

    { path: '/news', component: NewsIndex, name: 'newsIndex', meta: {current: ['news']} },

    { path: '/contents', component: ContentIndex, name: 'contentIndex', meta: {current: ['contents']} },

    { path: '*', redirect: '/' },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (to.matched.some(m => m.meta.disableScroll)) return;

    const position = {
      x: 0,
      y: 0
    };

    if (savedPosition) {
      position.x = savedPosition.x;
      position.y = savedPosition.y;
    }

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(position)
      }, 100)
    });
  }
});

export default router;