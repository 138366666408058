var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "scroll" }, [
    _c(
      "ul",
      { staticClass: "home-news" },
      _vm._l(_vm.news, function(newsItem) {
        return _c("news-item", {
          key: newsItem.id,
          attrs: { "has-service-link": _vm.hasServiceLink, newsItem: newsItem }
        })
      }),
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }