<template>
  <layout>
    <router-view/>
  </layout>
</template>

<script>
import Layout from './commons/layout';
export default {
  name: 'App',
  components: {
    Layout
  },
  created() {
    let lang = 'ko';
    if (!this.$cookies.isKey('translink-lang')) {
      const userLang = navigator.language || navigator.userLanguage;
      if (userLang && userLang.toLowerCase() != 'ko') {
        lang = 'en';
      }

      this.$cookies.set('translink-lang', 'ko');
    } else {
      lang = this.$cookies.get('translink-lang');
    }

    this.$i18n.locale = lang;
  }
}
</script>
