var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "team" }, [
    _c("section", { staticClass: "team-member section-container" }, [
      _c("div", { staticClass: "content-container" }, [
        _c("div", { staticClass: "profile-wrapper" }, [
          _c("div", { staticClass: "img-wrapper" }),
          _vm._v(" "),
          _c("img", {
            staticClass: "profile-img",
            attrs: { src: _vm.member.profile_image_url }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "text-wrapper" }, [
          _c("div", { staticClass: "name-wrapper p-wrapper" }, [
            _c("p", { staticClass: "name type-member-name" }, [
              _vm._v("\n            " + _vm._s(_vm.getName) + "\n          ")
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "position type-member-position" }, [
              _vm._v(
                "\n            " + _vm._s(_vm.getPosition) + "\n          "
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "location-wrapper p-wrapper" }, [
            _c("div", { staticClass: "linkedin-wrapper" }, [
              _c(
                "a",
                { attrs: { href: _vm.member.linked_in, target: "_blank" } },
                [
                  _c("img", {
                    staticClass: "icon",
                    attrs: {
                      src:
                        "https://s3.ap-northeast-2.amazonaws.com/assets.translink/commons/sns_linkedin.svg"
                    }
                  })
                ]
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "location" }, [
              _vm._v(
                "\n            Location: " +
                  _vm._s(_vm.member.locales[0].location) +
                  "\n          "
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "description-wrapper" }, [
            _vm._v(
              "\n          " +
                _vm._s(_vm.member.locales[0].description) +
                "\n        "
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }