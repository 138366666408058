var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "categories" },
    [
      _c(
        "div",
        {
          staticClass: "category",
          class: { active: _vm.selectedCategory == 0 },
          on: {
            click: function($event) {
              return _vm.selected(0)
            }
          }
        },
        [
          _vm.hasLanguage
            ? _c("span", [_vm._v(_vm._s(_vm.$t("message.categories.all")))])
            : _c("span", [_vm._v("All")])
        ]
      ),
      _vm._v(" "),
      _vm._l(_vm.categories, function(category) {
        return _c(
          "div",
          {
            staticClass: "category",
            class: { active: _vm.selectedCategory == category.id },
            on: {
              click: function($event) {
                return _vm.selected(category.id)
              }
            }
          },
          [
            _vm.hasLanguage
              ? _c("span", [_vm._v(_vm._s(_vm.$t(category.name)))])
              : _c("span", [_vm._v(_vm._s(category.name))])
          ]
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }