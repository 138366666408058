var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("header", { class: { nav: true, white: _vm.isWhite } }, [
    _c(
      "div",
      { staticClass: "nav-wrapper" },
      [
        _c(
          "router-link",
          {
            staticClass: "logo-wrapper remove-line-height",
            attrs: { to: { name: "home" } }
          },
          [_c("img", { staticClass: "logo", attrs: { src: _vm.logoImage } })]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "side-menus" }, [
          _c("div", { staticClass: "menus-activator mobile-only" }, [
            _c(
              "button",
              {
                staticClass: "button button-hamburger",
                on: { click: _vm.activeMobileMenu }
              },
              [
                _c("div", { staticClass: "item" }),
                _vm._v(" "),
                _c("div", { staticClass: "item" }),
                _vm._v(" "),
                _c("div", { staticClass: "item" })
              ]
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", {
          staticClass: "invisible",
          class: _vm.activeMobile,
          on: { click: _vm.closeMobileMenu }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "menus-wrapper", class: _vm.activeMobile }, [
          _c("ul", { staticClass: "menus" }, [
            _c("li", { staticClass: "menu" }, [
              _c(
                "div",
                { class: { item: true, active: _vm.current == "about" } },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "name",
                      attrs: { to: { name: "aboutIndex" } }
                    },
                    [_c("span", [_vm._v("About")])]
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "menu" }, [
              _c(
                "div",
                { class: { item: true, active: _vm.current == "team" } },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "name",
                      attrs: { to: { name: "teamIndex" } }
                    },
                    [_c("span", [_vm._v("Team")])]
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "menu" }, [
              _c(
                "div",
                { class: { item: true, active: _vm.current == "portfolio" } },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "name",
                      attrs: { to: { name: "portfolioIndex" } }
                    },
                    [_c("span", [_vm._v("Portfolio")])]
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "menu" }, [
              _c(
                "div",
                { class: { item: true, active: _vm.current == "news" } },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "name",
                      attrs: { to: { name: "newsIndex" } }
                    },
                    [_c("span", [_vm._v("News & Events")])]
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "menu" }, [
              _c(
                "div",
                { class: { item: true, active: _vm.current == "contents" } },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "name",
                      attrs: { to: { name: "contentIndex" } }
                    },
                    [_c("span", [_vm._v("Content")])]
                  )
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c("ul", { staticClass: "menus lang" }, [
            _c("li", { staticClass: "menu" }, [
              _c(
                "div",
                {
                  class: { item: true, active: _vm.isKorean },
                  on: {
                    click: function($event) {
                      return _vm.changeLang("ko")
                    }
                  }
                },
                [_c("span", { staticClass: "name" }, [_vm._v("KOR")])]
              )
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "menu" }, [
              _c(
                "div",
                {
                  class: { item: true, active: _vm.isEnglish },
                  on: {
                    click: function($event) {
                      return _vm.changeLang("en")
                    }
                  }
                },
                [_c("span", { staticClass: "name" }, [_vm._v("ENG")])]
              )
            ])
          ])
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }